<template>
  <div class="order-details-container">
    <div
      style="padding-top: 55px;background: none;"
      class="order-detail-content"
    >
      <div class="composite">
        <div class="list">
          <p class="order-detail-item-title">快递公司：</p>
          <p
            class="order-detail-item-title"
            style="color: #FEFEFE;"
          >{{orderInfo.shipperName}}</p>
        </div>
        <div
          class="list"
          style="margin-top:10px"
        >
          <p class="order-detail-item-title">快递单号:</p>
          <p
            class="order-detail-item-title"
            style="color: #FEFEFE;"
          >
            {{orderInfo.logisticCode}}
            <a
              style="margin-left:15px"
              class="orderNo-copy"
              :data-clipboard-text="orderInfo.orderId"
              @click="copy"
            >复制</a>
          </p>

          <!-- <p class="list-detail-name" style="margin-left:21px">{{ orderInfo.address.phone }}</p> -->
        </div>

      </div>
      <div
        v-if="noOrder"
        class="col-center"
        style="width:100%;height: calc(100vh - 8rem);"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
      <van-steps
        v-if="!noOrder"
        direction="vertical"
        :active="0"
        active-color="#fff"
        style="background: #262728;color:#fffff;margin-top:5px"
      >
        <van-step
          v-for="(item,index) in orderInfo.traces"
          :key="index"
        >
          <h3>{{item.acceptStation}}</h3>
          <p>{{item.acceptTime}}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import api from "../api/index-client";
import Clipboard from "clipboard";
import Vue from "vue";
import { CountDown,Popup,Button,Step,Steps } from "vant";
import { Dialog,Radio,Input,RadioGroup } from "element-ui";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Step);
Vue.use(Steps);
export default {
  data () {
    return {
      noOrder: true,
      orderInfo: {
        address: {
          realName: '111',
        }
      },
    };
  },
  created () {
    this.$store.commit("SHOW_APPLOADING");
    this.getOrderDetailsInfo();
  },
  methods: {
    // 获取订单详情信息
    getOrderDetailsInfo () {
      // 兑换订单详情
      api
        .post('order/express/' + this.$route.query.orderId)
        .then(result => {
          this.$store.commit("HIDE_APPLOADING");
          if (result.data.success) {
            this.orderInfo = result.data.data
            this.noOrder = result.data.data.traces.length == 0 ? true : false
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }

        });
    },
    // 复制订单号
    copy () {
      var clipboard = new Clipboard(".orderNo-copy");
      clipboard.on("success",(e) => {
        this.$toast.success("复制成功");
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast.fail("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    },
  }
}
</script>

<style lang="less" scoped>
.order-detail-content {
  width: 94%;
  // margin: 0.625rem 3%;
  border-radius: 0.5rem;
}
.composite {
  width: 100%;
  margin-top: 0.625rem;
  // background: #262728;
  border-radius: 8px;
  padding: 13px 0;
  .title {
    font-size: 18px;
    color: #ffffff;
    line-height: 22px;
    margin: 0 0 8px 21px;
  }
  .list {
    display: flex;
    font-size: 16px;
    width: 100%;
    .order-detail-item-title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
    }
    .list-detail-name {
      font-size: 16px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
    }
  }
  .expand {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 12px;
      color: #666666;
    }
    img {
      width: 14px;
      height: 9px;
      margin-left: 6px;
    }
  }
}
</style>